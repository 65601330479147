<template>
  <div class="brand-item-info">
    <div class="swiper-box">
      <swiper ref="mySwiper" :options="swiperOption" v-if="swiperImgList.length > 0">
        <swiper-slide class="swiper-slide" v-for="(item,index) in swiperImgList" :key="index">
          <img :src="item.picUrl" alt="" />
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination">
        </div>
      </swiper>
    </div>
    <div class="item-title">
      <c-title :width="16" :type="2">
        <div class="text">{{$store.state.language ? info.brandName : info.brandNameE }}</div>
      </c-title>
    </div>
    <div class="item-content">
      <div class="profile-title">[ {{$t('introduction')}} ]</div>
      <div class="profile" :class="{'profile-indent': $store.state.language}" v-html="$store.state.language ? info.brandProfile : info.brandProfileE"></div>
      <div class="html-box">
        <div v-html="info.articleDetails" class="left-html"></div>
        <div v-html="info.articleDetails2" class="right-html"></div>
      </div>
    </div>
    <div class="link">
      <div @click="linkClick()" class="text">{{$t('relevantImage')}}</div>
    </div>
    <sidebar :qrCodeUrl="info.wechatQrCode"></sidebar>
  </div>
</template>
<script>
import { brandItemInfo } from '@/api/brand'
import cTitle from '@/components/cTitle/cTitle.vue'

import sidebar from '@/components/sidebar/sidebar'

export default {
  name: 'brandItem',
  components: {
    cTitle,
    sidebar
  },
  data() {
    return {
      swiperOption: {
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'customs',
          bulletActiveClass: 'customs-active',
          renderBullet(index, className) {
            return '<div class="' + className + '"></div>'
          }
        },
        //自动轮播
        autoplay: {
          delay: 3000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        },
        //开启循环模式
        loop: true
      },
      info: {},
      swiperImgList: []
    }
  },
  computed: {},
  watch: {
    swiperImgList(val) {
      if (val.length === 1) {
        this.swiperOption.loop = false
        this.swiperOption.pagination = {}
      }
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    getInfo() {
      brandItemInfo({ brandId: this.$route.query.brandId }).then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          let data = res.data
          data.articleDetails = data.articleDetails.replace(
            /<img/g,
            "<img style='max-width:100%;height: auto;'"
          )
          data.articleDetails2 = data.articleDetails2.replace(
            /<img/g,
            "<img style='max-width:100%;height: auto;'"
          )
          this.info = data
          this.swiperImgList = this.$store.state.language
            ? this.info.tabBrandWebPicList
            : this.info.tabBrandWebPicEnglishList
          this.$store.commit('addLinks', data.tabBrandWebsiteLinkList)
          this.$route.meta.title = data.brandName
          this.$route.meta.titleE = data.brandNameE
        }
      })
    },
    linkClick() {
      this.$router.push({
        path: '/iconStoreHouse/iconItem',
        query: {
          brandId: this.info.id
        }
      })
    }
  }
}
</script>
<style lang="scss">
.brand-item-info {
  width: 1140px;
  margin: 21px auto 0;
  .swiper-box {
    width: 100%;
    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 6%;
        .customs {
          width: 47px;
          height: 21px;
          margin: 0 6px;
          background: url('../../static/img/home/customs.png') no-repeat center;
          background-size: 100% 100%;
        }
        .customs-active {
          background: url('../../static/img/home/customs_active.png') no-repeat
            center;
          background-size: 100% 100%;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
    .text {
      color: #595757;
      font-size: 30px;
      line-height: 30px;
    }
  }
  .item-content {
    margin: 30px 0 0 0;
    background: url('../../static/img/brand/content_bg.jpg') no-repeat center;
    background-size: 100% 100%;
    padding: 46px 0;
    li {
      list-style: decimal;
      margin: 0 0 0 12px;
    }
    .profile-title {
      padding: 0 35px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      margin: 0 0 10px 0;
    }
    .profile {
      padding: 0 35px;
      font-size: 16px;
      margin: 0 0 46px 0;
    }
    .profile-indent {
      // text-indent: 32px;
    }
    .html-box {
      display: flex;
      justify-content: space-between;
      padding: 0 45px;
      .left-html {
        width: 50%;
        padding: 0 20px 0 0;
      }
      .right-html {
        width: 50%;
        padding: 0 0 0 20px;
      }
    }
  }
  .link {
    font-size: 17px;
    line-height: 17px;
    color: #595757;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 45px 0;
    .text {
      background: url('../../static/img/layout/link_icon2.png') no-repeat left
        center;
      background-size: 28px 9px;
      padding: 0 0 0 35px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>